/* eslint-disable import/prefer-default-export */
import {
  Beat,
  Bounce,
  Tunnel,
  ClimbingBox,
  Clip,
  Clock,
  Dot,
  Fade,
  Grid,
  Hash,
  Moon,
  Pacman,
  Propagate,
  Puff,
  Pulse,
  Ring,
  Rise,
  Rotate,
  Scale,
  Skew,
  Square,
  Sync,
} from './private/ReactSpinners.react';

import {
  Audio,
  BallTriangle,
  Bars,
  Circles,
  GridFade,
  Hearts,
  MutatingDots,
  Oval,
  Target,
  RevolvingDot,
  Rings,
  TailSpin,
  ThreeDots,
  Triangle,
} from './private/ReactLoaderSpinners.react';

import {Custom} from './private/CustomSpinners.react';

import {
  DualRing,
  Ellipsis,
  Hourglass,
  RingChase,
  Ripple,
  Roller,
  SpinningDisc,
  Wave,
} from './private/ReactCSSSpinners.react';

export {
  Beat,
  Bounce,
  Tunnel,
  ClimbingBox,
  Clip,
  Clock,
  Dot,
  Fade,
  Grid,
  Hash,
  Moon,
  Pacman,
  Propagate,
  Puff,
  Pulse,
  Ring,
  Rise,
  Rotate,
  Scale,
  Skew,
  Square,
  Sync,
  Audio,
  BallTriangle,
  Bars,
  Circles,
  GridFade,
  Hearts,
  MutatingDots,
  Oval,
  Target,
  RevolvingDot,
  Rings,
  TailSpin,
  ThreeDots,
  Triangle,
  Custom,
  DualRing,
  Ellipsis,
  Hourglass,
  RingChase,
  Ripple,
  Roller,
  SpinningDisc,
  Wave,
};
